'use strict';

$(function() {
	function setup() {
        Fancybox.bind("[data-fancybox]", {});

        const swiper = new Swiper('.swiper', {
            autoplay: {
                delay: 5000,
            },

            // Optional parameters
            direction: 'horizontal',
            loop: true,

            autoScrollOffset: 0,
            multipleActiveThumbs: true,
            slideThumbActiveClas: 'swiper-slide-thumb-active',
            thumbsContainerClass: 'swiper-thumbs',
          
            // If we need pagination
            pagination: {
              el: '.swiper-pagination',
              clickable: true,
            },
          
            // Navigation arrows
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            },
          });
	}
    setup();
});
